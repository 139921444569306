import React from "react";
import clsx from "clsx";
import {
  withStyles,
  Container,
  Grid,
  Paper,
  Button,
  CircularProgress,
  Divider,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { Component } from "react";
import { PropTypes } from "prop-types";
import TextField from "@material-ui/core/TextField";
import { withSnackbar } from "notistack";
import IconButton from "@material-ui/core/IconButton";
import AddCircle from "@material-ui/icons/AddCircle";
import Delete from "@material-ui/icons/Delete";

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paperCol: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    minHeight: 240,
  },
  textField: {
    width: 150,
  },
  submitButton: {
    width: 150,
  },
  iconButton: {
    marginLeft: 40,
  },
  startPaper: {
    padding: theme.spacing(2),
    display: "flex",
    backgroundColor: "#546bd6",
    justifyContent: "center",
  },
  tokenPaper: {
    padding: theme.spacing(2),
    display: "flex",
    backgroundColor: "#797979",
    justifyContent: "center",
  },
  heading: {
    fontSize: "20px",
    color: "white",
    fontWeight: 600,
  },
  mainBack: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  defaultToken: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  userToken: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  userHeading: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  playIcon: {
    color: "white",
  },
  userName: {
    fontSize: "16px",
    color: "white",
    fontWeight: 600,
  },
  activeLoading: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiFormLabel-root": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-input": {
      color: "white",
      fontWeight: 800,
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  },
})(TextField);

class SetTokens extends Component {
  getTokenView = () => {
    const { classes } = this.props;
    if (!this.props.tokenData.default) {
      return "";
    }

    return (
      <Paper className={classes.tokenPaper}>
        <Grid justify="space-between" container spacing={3}>
          <Grid className={classes.defaultToken} item>
            {Object.keys(this.props.tokenData.default_position).map(
              (key, index) => {
                return (
                  <CssTextField
                    className={classes.margin}
                    label="Token1"
                    id="user-token1"
                    name="user-token1"
                    type="number"
                    autoComplete="on"
                    onChange={(event) => {
                      this.props.updateUserDefaultToken(
                        key,
                        parseInt(event.target.value)
                      );
                    }}
                    key={index}
                    variant="outlined"
                    value={this.props.tokenData.default_position[key]}
                  />
                );
              }
            )}
          </Grid>
        </Grid>
      </Paper>
    );
  };

  getUsers = () => {
    const { classes } = this.props;
    let Users = [];
    for (let user in this.props.tokenData.user_position) {
      let data = this.props.tokenData.user_position[user];
      Users.push(
        <Grid style={{ width: "100%" }} item>
          <Divider style={{ backgroundColor: "white" }} />
          <br />
          <Grid className={classes.userHeading} item>
            <div className={classes.userName}>{user}</div>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              className={classes.iconButton}
              onClick={() => {
                this.props.deleteUser(user);
              }}
            >
              <Delete className={classes.playIcon} />
            </IconButton>
          </Grid>
          <br />
          <Grid className={classes.defaultToken} item>
            {Object.keys(data).map((key, index) => {
              return (
                <CssTextField
                  className={classes.margin}
                  label="Token1"
                  id="user-token1"
                  name="user-token1"
                  type="number"
                  autoComplete="on"
                  onChange={(event) => {
                    this.props.updateUserToken(
                      user,
                      key,
                      parseInt(event.target.value)
                    );
                  }}
                  key={index}
                  variant="outlined"
                  value={data[key]}
                />
              );
            })}
          </Grid>
        </Grid>
      );
    }
    return Users;
  };

  getUserView = () => {
    const { classes } = this.props;
    if (!this.props.tokenData.default) {
      return "";
    }
    return (
      <Paper className={classes.tokenPaper}>
        <Grid justify="space-between" container spacing={3}>
          <Grid className={classes.userToken} item>
            <div className={classes.heading}>Users</div>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              className={classes.iconButton}
              onClick={this.props.openAddUserDialog}
            >
              <AddCircle fontSize="large" className={classes.playIcon} />
            </IconButton>
          </Grid>
          {this.getUsers()}
        </Grid>
      </Paper>
    );
  };

  render() {
    const { classes } = this.props;
    const fixedHeightPaper = clsx(classes.paperCol, classes.fixedHeight);
    if (this.props.tokenLoading) {
      return (
        <div className={classes.activeLoading}>
          <CircularProgress />
        </div>
      );
    }

    return (
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Paper className={classes.startPaper}>
              <Grid justify="space-between" container spacing={3}>
                <Grid className={classes.mainBack} item>
                  <div className={classes.heading}>Set Tokens</div>
                  <div>
                    <Button
                      className={classes.updateButton}
                      aria-haspopup="true"
                      aria-controls="lock-menu"
                      variant="contained"
                      disabled={this.props.tokenData.default == false}
                      onClick={this.props.putDebugTokenData}
                    >
                      Update
                    </Button>
                    <Switch
                      checked={this.props.tokenData.default}
                      onChange={this.props.changeTokenDefault}
                      name="checkedA"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
            <br></br>
            <FormControlLabel
              control={
                <Switch
                  checked={this.props.tokenData.six_player}
                  onChange={this.props.changeSixPlayerDefault}
                  name="checkedB"
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              }
              label="6Player"
            />
            <br></br>
            <br></br>
            {this.getTokenView()}
            <br></br>
            <br></br>
            {this.getUserView()}
          </Grid>
        </Grid>
      </Container>
    );
  }
}

SetTokens.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(withSnackbar(SetTokens));
