import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Button, ButtonGroup } from '@material-ui/core';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  startPaper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: "center"
  },
  commitTitle: {
    fontSize: 16,
    fontWeight: 500
  },
  commitAuthor: {
    fontSize: 13
  },
  commitOptions: {
    justifyContent: "right",
    alignItems: "center",
    display: "flex"
  },
  buttonGroup: {
    justifyContent: "right",
    alignItems: "center",
    display: "grid"
  }
}));

export default function Commit(props) {
  const classes = useStyles();
  return (
    <Grid item>
      <Paper className={classes.startPaper}>
        <Grid container>
          <Grid item xs={8} md={8} lg={8}>
            <span className={classes.commitTitle}>{props.commit.title}</span>
            <br />
            <span className={classes.commitAuthor}>{props.commit.author}</span>
          </Grid>
          <Grid className={classes.commitOptions} item xs={4} md={4} lg={4}>
            <Grid container alignItems="center" className={classes.buttonGroup}>
              <ButtonGroup disableElevation color="primary" variant="contained">
                <Button disabled>{props.commit.id.substring(0, 6)}</Button>
                <Button
                  disabled={props.deployLoading}
                  style={{ "backgroundColor": "#26a252" }}
                  onClick={() => {
                    props.deployBranch(props.commit)
                  }}
                >Deploy</Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

Commit.propTypes = {
  children: PropTypes.node,
};
