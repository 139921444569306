import React from 'react';
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import User from './User';
import { MenuItem, withStyles, Menu, Button, Paper } from '@material-ui/core';
import { Component } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EmojiEvents from '@material-ui/icons/EmojiEvents';
import Person from '@material-ui/icons/Person';
import PieChart from '@material-ui/icons/PieChart';
import Backup from '@material-ui/icons/Backup';
import SportsEsports from '@material-ui/icons/SportsEsports';
import { PropTypes } from 'prop-types';
import axios from 'axios';
import Deploy from './Deploy';
import SetTokens from './SetTokens';
import EventView from './EventView';
import { withSnackbar } from 'notistack';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

const drawerWidth = 240;
const useStyles = (theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    backgroundColor: "#efefef",
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    color: "white"
  },
  devSelectButton: {
    backgroundColor: "white",
    marginRight: theme.spacing(5),
    width: theme.spacing(18),
  },
  icon: {
    width: 43,
    height: 50,
    marginRight: 10,
    marginBottom: 6
  },
  logView: {
    position: "fixed",
    height: "350px",
    width: "400px",
    right: 0,
    bottom: 0
  },
  confirmDialog: {
    padding: theme.spacing(5)
  }
});

class Dashboard extends Component {
  state = {
    open: true,
    dev: "",
    devSelectOpen: false,
    anchorEl: null,
    selectedIndex: -1,
    currentView: "user-view",
    branches: [],
    commits: [],
    userData: {
    },
    userLeagueData: {},
    leagueData: {},
    logs: [],
    activeBranches: [],
    selectedBranch: "",
    activeCommit: {
      title: ""
    },
    gameCode: "",
    loading: false,
    loadingBranch: false,
    acitveLoading: false,
    deployConfigOpen: false,
    deployLoading: false,
    addUserDialog: false,
    tokenUserCode: "",
    tokenData: {
      "default": true,
      "default_position": {
        "0": 1,
        "1": 2,
        "2": 3,
        "3": 4
      },
      "user_position": {
      }
    },
    eventData: {
      type: "Cannon Event",
      selectedEvent: "1",
      data: {},
    },
    tokenLoading: false,
    eventLoading: false,
    command: '{"$set": {"ev.p": 100}}',
    serverNames: [],
    userPageLoading: false,
  }

  componentDidMount = () => {
    axios
      .get("/servers")
      .then(json => json.data)
      .then(serverNames => {
        this.setState({ serverNames: serverNames, selectedIndex: 0 })
        this.handleDevSelect(null, this.state.selectedIndex)
        console.log(`udpated servers are ${this.state.serverNames}, ${this.state.selectedIndex}`)
      })
      .catch(err => {
        console.error(`error loading server names, error: ${err}`)
      })

    this.setState({ loadingBranch: true })
    axios
      .get("api/branches")
      .then(json => json.data)
      .then(data => {
        console.log(data);
        if (data.err) {
          this.setState({ loadingBranch: false })
          console.log(data.err);
        } else {
          this.setState({ branches: data, loadingBranch: false })
        }
      })
      .catch(err => {
        this.setState({ loadingBranch: false })
        console.log(err);
      });
    this.getDebugTokenData(this.state.dev);
    this.getActiveBranches();
  }

  getDebugTokenData = (dev) => {
    this.setState({ tokenLoading: true })
    axios
      .get("/api/dev/" + dev + "/consul/debugTokenPosition")
      .then(json => json.data)
      .then(data => {
        this.setState({ tokenLoading: false, tokenData: data })
        console.log(data);
      })
      .catch(err => {
        this.setState({ tokenLoading: false })
        console.log(err);
      });
  }

  getEventData = (dev, event) => {
    this.setState({ eventLoading: true })

    axios
      .get("/api/dev/" + dev + "/consul/eventTable/event/" + event)
      .then(json => json.data)
      .then(data => {
        let state = Object.assign({}, this.state);
        state.eventData.data[event] = data;
        state.eventLoading = false;
        this.setState(state);
        console.log(data);
      })
      .catch(err => {
        this.setState({ eventLoading: false })
        console.log(err);
      });
  }

  putDebugTokenData = () => {
    console.log(this.state.tokenData)
    axios
      .post("/api/dev/" + this.state.dev + "/consul/debugTokenPosition", this.state.tokenData)
      .then(json => json.data)
      .then(data => {
        console.log(data);
      })
      .catch(err => {
        console.log(err);
      });
  }

  fetchCommits = (branch) => {
    this.setState({ loading: true })
    console.log("/api/commits/" + branch);
    axios
      .get("api/commits/" + branch)
      .then(json => json.data)
      .then(data => {
        console.log(data);
        if (data) {
          if (data.err) {
            console.log(data.err);
            this.setState({ loading: false })
          } else {
            this.setState({ commits: data, loading: false })
          }
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false })
      });
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  }

  handleDrawerClose = () => {
    this.setState({ open: false });
  }

  handleDevSelect = (event, index) => {
    this.setState({
      dev: this.state.serverNames[index],
      userData: {},
      selectedIndex: index,
      devSelectOpen: false
    });
    this.getDebugTokenData(this.state.serverNames[index]);
  }

  handleUserInfoChange = (id, value) => {
    var set = require('lodash.set');
    let state = Object.assign({}, this.state);
    set(state.userData, id, value);
    this.setState(state);
  }

  handleEventInfoChange = (id, value) => {
    var set = require('lodash.set');
    let state = Object.assign({}, this.state);
    set(state.eventData.data[state.eventData.selectedEvent], id, value);
    this.setState(state);
  }

  selectBranch = (event, newValue) => {
    if (event) {
      this.setState({ "selectedBranch": newValue });
      this.fetchCommits(newValue)
    }
  }

  changeSelectBranchInput = (event, newValue) => {
    if (event) {
      this.setState({ "selectedBranchInput": newValue, commits: [] });
    }
  }

  getActiveBranches = () => {
    this.setState({ acitveLoading: true })
    axios
      .get("api/activeBranches")
      .then(json => json.data)
      .then(data => {
        this.setState({ acitveLoading: false })
        console.log(data);
        if (data.err) {
          console.log(data.err);
        } else {
          this.setState({ activeBranches: data })
        }
      })
      .catch(err => {
        this.setState({ acitveLoading: false })
        console.log(err);
      });
  }

  deployBranch = () => {
    this.setState({ deployLoading: true, deployConfigOpen: false })
    axios
      .get("api/dev/" + this.props.dev + "/deploy/" + this.state.selectedBranch + "/commit/" + this.state.activeCommit.id)
      .then(json => json.data)
      .then(data => {
        this.setState({ deployLoading: false })
        if (data.err) {
          this.props.enqueueSnackbar('Deploy Failed', {
            variant: 'error',
          })
          console.log(data.err);
        } else {
          console.log(data);
          this.getActiveBranches();
          let state = Object.assign({}, this.state);
          state.logs.push(data.output)
          this.setState(state);
          this.props.enqueueSnackbar('Deploy Succesfully', {
            variant: 'success',
          })
        }
      })
      .catch(err => {
        this.setState({ deployLoading: false })
        this.props.enqueueSnackbar('Deploy Failed', {
          variant: 'error',
        })
        console.log(err);
      });
  }

  openConfirmModal = (commit) => {
    this.setState({ activeCommit: commit, deployConfigOpen: true })
  }

  handleGameCodeChange = (event) => {
    this.setState({ "gameCode": event.target.value });
  }

  handleUserCommandChange = (event) => {
    this.setState({ "command": event.target.value });
  }

  setUserData = (data) => {
    if (Object.keys(data).length != 0) {
      data["RandAlbumNo"] = 0
      data["ResetAlbumNo"] = 0
      data["AlbumNo"] = 0
      data["CollectionNo"] = 0
      data["CardNo"] = 0
      data["collectionType"] = ""
      data["collectionID"] = 0
      data["count"] = 0
    }
    this.setState({ "userData": data });
  }

  setUserLeagueData = (data) => {
    this.setState({ "userLeagueData": data });
  }

  setLeagueData = (data) => {
    this.setState({ "leagueData": data });
  }

  splitText(text, className) {
    let array = [];
    text.split("\n").map((i, key) => {
      array.push(
        <p className={className} key={key}>
          {i}
        </p>
      );
    });

    return array;
  }

  addUserToToken = () => {
    let state = Object.assign({}, this.state);
    state.tokenData.user_position[this.state.tokenUserCode] = {
      "0": 0,
      "1": 1,
      "2": 2,
      "3": 3
    }
    state.addUserDialog = false;
    state.tokenUserCode = "";
    this.setState(state);
  }

  changeTokenDefault = (event) => {
    let state = Object.assign({}, this.state);
    state.tokenData.default = !state.tokenData.default
    this.setState(state);
    this.putDebugTokenData();
  }

  changeSixPlayerDefault = (event) => {
    let state = Object.assign({}, this.state);
    state.tokenData.six_player = !state.tokenData.six_player
    this.setState(state);
    this.putDebugTokenData();
  }

  openAddUserDialog = () => {
    this.setState({ addUserDialog: true })
  }

  deleteUser = (gameCode) => {
    let state = Object.assign({}, this.state);
    delete state.tokenData.user_position[gameCode]
    this.setState(state);
  }

  updateUserDefaultToken = (key, value) => {
    let state = Object.assign({}, this.state);
    state.tokenData.default_position[key] = value;
    this.setState(state);
  }

  updateUserToken = (user, key, value) => {
    let state = Object.assign({}, this.state);
    state.tokenData.user_position[user][key] = value;
    this.setState(state);
  }

  toggleUserLoading = () => {
    var isUserPageLoading = this.state.userPageLoading;
    this.setState({ userPageLoading: !isUserPageLoading })
  }

  getActiveView = () => {
    switch (this.state.currentView) {
      case "user-view":
        return (<User
          userData={this.state.userData}
          userLeagueData={this.state.userLeagueData}
          leagueData={this.state.leagueData}
          handleChange={this.handleUserInfoChange}
          handleGameCodeChange={this.handleGameCodeChange}
          handleUserCommandChange={this.handleUserCommandChange}
          command={this.state.command}
          gameCode={this.state.gameCode}
          setUserData={this.setUserData}
          setUserLeagueData={this.setUserLeagueData}
          setLeagueData={this.setLeagueData}
          dev={this.state.dev}
          userLoading={this.state.userPageLoading}
          toggleUserLoading={this.toggleUserLoading}
        />);
      case "event-view":
        return (<EventView
          eventData={this.state.eventData}
          handleChange={this.handleEventInfoChange}
          selectEvent={(value, selectedEvent) => {
            let state = Object.assign({}, this.state);
            state.eventData.type = value;
            state.eventData.selectedEvent = selectedEvent;
            this.setState(state);
          }}
        />)
      case "experiment-view":
        return (<React.Fragment />)
      case "deploy-view":
        return (<Deploy
          devs={this.state.serverNames}
          acitveLoading={this.state.acitveLoading}
          activeBranches={this.state.activeBranches}
          deployBranch={this.openConfirmModal}
          commits={this.state.commits}
          branches={this.state.branches}
          selectBranch={this.selectBranch}
          selectedBranch={this.state.selectedBranch}
          fetchCommits={this.fetchCommits}
          changeSelectBranchInput={this.changeSelectBranchInput}
          loading={this.state.loading}
          loadingBranch={this.state.loadingBranch}
          deployLoading={this.state.deployLoading}
        />);
      case "token-view":
        return (<SetTokens
          changeTokenDefault={this.changeTokenDefault}
          changeSixPlayerDefault={this.changeSixPlayerDefault}
          tokenData={this.state.tokenData}
          tokenLoading={this.state.tokenLoading}
          openAddUserDialog={this.openAddUserDialog}
          deleteUser={this.deleteUser}
          putDebugTokenData={this.putDebugTokenData}
          updateUserDefaultToken={this.updateUserDefaultToken}
          updateUserToken={this.updateUserToken}
        />)
    }
  }

  handleClose = () => {
    this.setState({ deployConfigOpen: false });
  };

  handleSignout = () => {
    axios.post("/signout")
      .then(email => {
        window.google.accounts.id.revoke(email, done => {
          if (done.error !== '') {
            console.error(`error in logout: ${done.error}`)
          } else {
            console.log(`user logged out successfully`)
          }
        })
        this.props.setIsAuthed(false)
        window.location.reload()
      })
      .catch(err => {
        console.log(`error in signout, error: ${err}`)
        alert("signout failed")
      })
  }

  render() {
    const { classes } = this.props;
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="absolute" className={clsx(classes.appBar, this.state.open && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerOpen}
              className={clsx(classes.menuButton, this.state.open && classes.menuButtonHidden)}
            >
              <MenuIcon />
            </IconButton>
            <img className={classes.icon} src="icon_logo.png"></img>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
              Dev Tool | Ludo Star
            </Typography>
            <Button
              className={classes.devSelectButton}
              aria-haspopup="true"
              aria-controls="lock-menu"
              variant="contained"
              onClick={(event) => {
                this.setState({ "anchorEl": event.currentTarget, devSelectOpen: true });
              }}
            >
              {this.state.dev}
            </Button>
            <Menu
              id="lock-menu"
              anchorEl={this.state.anchorEl}
              keepMounted
              open={this.state.devSelectOpen}
              onClose={() => {
                this.setState({ devSelectOpen: false });
              }}
            >
              {this.state.serverNames.map((option, index) => (
                <MenuItem
                  key={option}
                  selected={index === this.state.selectedIndex}
                  onClick={(event) => this.handleDevSelect(event, index)}
                >
                  {option}
                </MenuItem>
              ))}
            </Menu>
            <Button
              onClick={() => {
                this.handleSignout()
              }}
              color="inherit"
            >
              Signout
            </Button>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
          }}
          open={this.state.open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            <div>
              <ListItem button onClick={() => {
                this.setState({ "currentView": "user-view" })
              }}>
                <ListItemIcon>
                  <Person />
                </ListItemIcon>
                <ListItemText primary="User" />
              </ListItem>
              <ListItem button disabled onClick={() => {
                this.setState({ "currentView": "event-view" })
              }}>
                <ListItemIcon>
                  <EmojiEvents />
                </ListItemIcon>
                <ListItemText primary="Events" />
              </ListItem>
              <ListItem button disabled>
                <ListItemIcon>
                  <PieChart />
                </ListItemIcon>
                <ListItemText primary="Experiment" />
              </ListItem>
              <ListItem button onClick={() => {
                this.setState({ "currentView": "deploy-view" })
              }}>
                <ListItemIcon>
                  <Backup />
                </ListItemIcon>
                <ListItemText primary="Deploy Branch" />
              </ListItem>
              <ListItem button onClick={() => {
                this.setState({ "currentView": "token-view" })
              }}>
                <ListItemIcon>
                  <SportsEsports />
                </ListItemIcon>
                <ListItemText primary="Set Tokens" />
              </ListItem>
            </div>
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          {this.getActiveView()}
        </main>
        <Dialog
          open={this.state.deployConfigOpen}
          onClose={this.handleClose}
          className={classes.confirmDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Are You Sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Deploying {this.state.selectedBranch} ({this.state.activeCommit.title}) to <b>{this.state.dev}</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} style={{ color: "red" }}>
              Cancel
            </Button>
            <Button onClick={this.deployBranch} color="primary" autoFocus>
              Deploy
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.addUserDialog}
          onClose={() => {
            this.setState({ addUserDialog: false });
          }}
          className={classes.formDialog}
          fullWidth={10}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <TextField
              className={classes.margin}
              label="GameCode"
              id="user-gamecode"
              name="user-gamecode"
              autoComplete='on'
              onChange={(event) => {
                this.setState({ tokenUserCode: event.target.value })
              }}
              variant="outlined"
              value={this.state.tokenUserCode}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              this.setState({ addUserDialog: false });
            }} style={{ color: "red" }}>
              Cancel
            </Button>
            <Button onClick={this.addUserToToken} color="primary" autoFocus>
              Add
            </Button>
          </DialogActions>
        </Dialog>
        {/* <Paper className={classes.logView}> {this.state.logs.map((log) => <div>{this.splitText(log, "")}</div>)} </Paper> */}
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(useStyles)(withSnackbar(Dashboard));
