import React from 'react';
import clsx from 'clsx';
import { withStyles, Container, Grid, Paper, Button, CircularProgress, Divider, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { Component } from 'react';
import { PropTypes } from 'prop-types';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Refresh from '@material-ui/icons/Refresh';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withSnackbar } from 'notistack';
import Commit from '../components/Commit';
import UserInfo from '../components/UserInfo';

const drawerWidth = 240;

const eventType = ["Cannon Event", "Cannon Event V2", "Streak Challenge", "Treasure Road", "Football Frenzy"]
const eventFields = [
  {
    sectionTitle: "Event Time",
    itemList: [
      {
        label: "Start Date",
        name: "start_date",
        type: "time"
      },
      {
        label: "End Date",
        name: "end_date",
        type: "time"
      }
    ]
  }
]

const useStyles = (theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paperCol: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    minHeight: 240,
  },
  submitButton: {
    width: 150
  },
  iconButton: {
    marginLeft: 40
  },
  startPaper: {
    padding: theme.spacing(2),
    display: 'flex',
    backgroundColor: "#ffffff",
    justifyContent: "center"
  },
  activeBranches: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: "center"
  },
  activeBranch: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: "left"
  },
  playIcon: {
    color: "white"
  },
  updateButton: {
    backgroundColor: "#546bd6",
    color: "white",
    marginTop: 10,
    marginBottom: 10,
    marginRight: 10
  },
  deleteButton: {
    backgroundColor: "#ec1010",
    color: "white",
    marginTop: 10,
    marginBottom: 10,
    marginRight: 10
  },
  autocomplete: {
    display: "inline-flex"
  },
  inputBar: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  timeInputBar: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    padding: theme.spacing(20)
  },
  loading: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    marginTop: theme.spacing(4)
  },
  activeLoading: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  branchLoader: {
    marginLeft: theme.spacing(3)
  }
});

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiFormLabel-root': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-input': {
      color: 'white',
      fontWeight: 800
    },

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
  },
})(TextField);



class EventView extends Component {

  render() {
    const { classes } = this.props;
    const fixedHeightPaper = clsx(classes.paperCol, classes.fixedHeight);
    if (this.props.tokenLoading) {
      return (<div className={classes.activeLoading}><CircularProgress /></div>)
    }

    var currentEventIndex = eventType.indexOf(this.props.eventData.type)
    var currentevent = this.props.eventData.data[this.props.eventData.selectedEvent]

    return (
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Paper className={classes.startPaper}>
              <Grid justify="space-between" container spacing={3}>
                <Grid item className={classes.inputBar}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="select-id">Event Type</InputLabel>
                    <Select
                      labelId="select-id"
                      id="select"
                      value={this.props.eventData.type}
                      name="event-select"
                      onChange={(event) => {
                        var currentEventIndex = eventType.indexOf(event.target.value)
                        this.props.selectEvent(event.target.value, (currentEventIndex + 1).toString())
                      }}
                      label="Event Type"
                    >
                      {eventType.map((optionText, index) => {
                        return (<MenuItem value={optionText}>{optionText}</MenuItem>)
                      })}
                    </Select>
                  </FormControl >
                  <Button
                    className={classes.updateButton}
                    aria-haspopup="true"
                    aria-controls="lock-menu"
                    variant="contained"
                  >
                    Deploy
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {eventFields.map((option, index) =>
          (
            <Grid item xs={12} md={4} lg={4}>
              <Paper className={fixedHeightPaper}>
                <UserInfo handleChange={this.props.handleChange} options={option} userData={currentevent} />
              </Paper>
            </Grid>
          )
          )}
        </Grid>
      </Container >
    );
  }
}

EventView.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(useStyles)(withSnackbar(EventView));
